import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import { Button, Center } from '@chakra-ui/react'

import { Layout, BodySection, Hero } from '../components/PageLayout'
import SEO from '../components/SEO'

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="404" description="Connecting David's home neighbourhood with 4G/LTE." />

      <Hero title="Page not found" text="We couldn't find the page you're looking for." />

      <BodySection>
        <Center>
          <Button variant="outline" colorScheme="pink" as={GatsbyLink} to="/">
            Visit our home page
          </Button>
        </Center>
      </BodySection>
    </Layout>
  )
}

export default IndexPage
